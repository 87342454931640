<template>

  <div class="activity-type-page">
    <v-row justify="center">
      <v-col md="6">

        <v-list v-for="item in activityTypes" :key="item.id">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">
                  <v-avatar size="24" :color="item.color"></v-avatar> 
                    <span class="mx-3">{{ item.name }}</span>
                </v-list-item-title>
              </v-list-item-content>
              
              <v-list-item-icon v-if="item.locked">
                <v-icon> mdi-lock </v-icon>
              </v-list-item-icon>

              <v-list-item-icon v-else>
                <v-icon class="mx-2" @click="createEditActivityTypeSheet.editId = item.id; createEditActivityTypeSheet.visible = true"> mdi-pencil </v-icon>
                <v-icon @click="deleteActivityDialog.objId = item.id; deleteActivityDialog.visible=true"> mdi-delete </v-icon>
              </v-list-item-icon>
              
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
          </v-list>
      </v-col>
    </v-row>

    <intract-create-edit-sheet
      persistent
      :title="createEditActivityTypeSheet.editId ? 'Edit Activity Type' : 'Add Activity Type'"
      :visible="createEditActivityTypeSheet.visible"
      @close="
        createEditActivityTypeSheet.visible = false;
        createEditActivityTypeSheet.editId = null;
      "
      :fields="formFields"
      :endpoint="endpoints.activityTypeViewSet"
      :edit-id="createEditActivityTypeSheet.editId"
      :data-object="createEditActivityTypeSheet.obj"
      create-success-message="Activity added successfully!"
      edit-success-message="Activity edited successfully!"
      @objectCreated="getAllActivityTypes"
      @updateObject="(obj) => (createEditActivityTypeSheet.obj = obj)"
    >
      <template v-slot:custom-field--color="{ obj }">
        <v-row>
          <div style="display: flex; align-items: center;" class="mx-4 mt-1">
            <label for="color-picker">
              <strong>Pick a color</strong>
            </label>
          </div>
          
          <div class="mt-2">
            <input type="color" name="color-picker" v-model="obj.color" style="border-radius: 50%; width: 50px; height: 50px; padding: 0.4rem">
          </div>
        </v-row>
      </template>

    </intract-create-edit-sheet>

    <confirmation-dialog
      :visible="deleteActivityDialog.visible"
      title="Are you sure you want to delete this activity type?"
      @successCallback="deleteActivity(deleteActivityDialog.objId)"
      @failureCallback="
        deleteActivityDialog.visible = false;
        deleteActivityDialog.objId = null; 
      "
      delete-dialog
    />
  
    <v-fab-transition>
      <v-btn
        v-if="!isLoading"
        color="primary"
        class="non-focused"
        @click="createEditActivityTypeSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

  </div>

</template>

<script>
import Mixins from "@utils/mixins";
import IntractCreateEditSheet, { CreateEditFieldTypes,} from "@components/generics/IntractCreateEditSheet.vue";
export default {
  name: "ActivityType",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions ],
  components: {
    IntractCreateEditSheet,
  },
  data() {
    return { 
      activityTypes: [],
      deleteActivityDialog: {
        visible: false, 
        objId: null, 
      }, 
      createEditActivityTypeSheet: {
        visible: false,
        editId: null,
        obj: {
          name: null,
          institution: null,
          color: null,
          allow_rooms: false,
          allow_subjects: false,
        },
      },
    };
  },

  computed: {
    formFields() {
      return {
        name: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Name",
          required: true,
          md: 12,
          max: 255,
        },
        verbose_singular: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Verbose Singular",
          required: true,
          md: 12,
          max: 255,
        },
        color: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          label: "Color",
          required: true,
          md: 12, 
        },
        allow_rooms: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: 'Allocate to Rooms',
          md: 12,
        },
        allow_subjects: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: 'Allocate to Specific Subjects of a selected room',
          md: 12,
        },
      };
    },       
  }, 

  methods: {

    async getAllActivityTypes(){
      this.activityTypes = await this.api.call(this.essentials, this.endpoints.activityTypeViewSet);
    }, 


    async deleteActivity() {
      this.deleteActivityDialog.visible = false;
      var url = this.endpoints.activityTypeViewSet + this.deleteActivityDialog.objId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Activity has been successfully deleted!",
          type: "success",
        });
        this.deleteActivityDialog.objId = null;
        this.getAllActivityTypes();
      }
    },

  },

  async created(){
    this.getAllActivityTypes();
    this.createEditActivityTypeSheet.obj.institution = this.currentInstitution.id;
  }
}
</script>

<style>

</style>